import { cpf, cnpj } from "cpf-cnpj-validator";
import { ColorPicker, Flex } from "antd";

const validatePasswordCreate = (_, value) => {
    if (!value) {
        return Promise.reject(new Error("Insira a senha!"));
    }
    if (value.length < 8) {
        return Promise.reject(
            new Error("A senha deve ter pelo menos 8 caracteres!")
        );
    }
    if (!/[A-Z]/.test(value)) {
        return Promise.reject(
            new Error("A senha deve conter pelo menos uma letra maiúscula!")
        );
    }
    if (!/[a-z]/.test(value)) {
        return Promise.reject(
            new Error("A senha deve conter pelo menos uma letra minúscula!")
        );
    }
    if (!/\d/.test(value)) {
        return Promise.reject(
            new Error("A senha deve conter pelo menos um número!")
        );
    }
    if (!/[\W_]/.test(value)) {
        return Promise.reject(
            new Error("A senha deve conter pelo menos um caractere especial!")
        );
    }
    return Promise.resolve();
};

const validatePasswordEdit = (_, value) => {
    if (!value) {
        return Promise.resolve();
    }
    if (value.length < 8) {
        return Promise.reject(
            new Error("A senha deve ter pelo menos 8 caracteres!")
        );
    }
    if (!/[A-Z]/.test(value)) {
        return Promise.reject(
            new Error("A senha deve conter pelo menos uma letra maiúscula!")
        );
    }
    if (!/[a-z]/.test(value)) {
        return Promise.reject(
            new Error("A senha deve conter pelo menos uma letra minúscula!")
        );
    }
    if (!/\d/.test(value)) {
        return Promise.reject(
            new Error("A senha deve conter pelo menos um número!")
        );
    }
    if (!/[\W_]/.test(value)) {
        return Promise.reject(
            new Error("A senha deve conter pelo menos um caractere especial!")
        );
    }
    return Promise.resolve();
};

const pattern = {
    endpoints: {
        auth: {
            login: "/login",
            checkAuth: "/check-auth",
            logout: "/logout",
        },
        account: {
            myAccount: "/account",
            update: "/account/update",
        },
        pdf: {
            signPdf: "/sign-pdf",
            downloadPdf: "/download-pdf",
        },
        users: {
            create: "/register/user",
            myUser: "/profile",
            updateMyUser: "/profile/update",
            update: "/user/update/${}",
            index: "/users",
            indexActiveDentists: "/users/dentists",
        },
        patients: {
            index: "/patients",
            create: "/register/patient",
            update: "/edit/patient/${}",
            uploadFiles: "/patients/upload/${}",
            downloadFile: "/patients/files/download/${}",
            deleteFile: "/patients/files/${}",
            renameFile: "/patients/edit/files/${}",
            renameFolder: "/patients/edit/folders/${}",
            createFolder: "/patients/folders/${}",
            deleteFolder: "/patients/folders/${}",
            getImageUrl: "/patients/files/signed-url/${}",
            getAvatarUrl: "/patients/avatar/signed-url/${}",
            files: "/patients/files/${}",
            indexUpdateConvenios:
                "/convenios/index-update/patients_convenios/patient_id/${}",
            uploadImage: "/upload-image",
            deleteImage: "/delete-image/${}",
        },
        schedules: {
            index: "/schedules",
            indexMe: "/schedules/me",
            scheduleDay: "/schedules-day/me",
            create: "/register/schedule",
            update: "/edit/schedule/${}",
            updateStatus: "/edit/schedule/status/${}",
            history: "/history/patient/${}",
            availableTimes: "/schedules/available-times",
            indexUpdateCategories:
                "/categories/index-update/schedules_categories/schedule_id/${}",
        },
        categories: {
            index: "/categories",
            indexCreate: "/categories/index-create",
            create: "/register/category",
            update: "/edit/category/${}",
        },
        treatments: {
            index: "/treatments",
            indexCreate: "/treatments/index-create",
            indexPatientTreatments: "/patient-treatments/${}",
            create: "/register/treatment",
            createPatientTreatments: "/register/patient-treatments",
            updatePatientTreatment: "/edit/patient-treatment/${}",
            finishPatientTreatment: "finish/patient-treatment/${}",
            update: "/edit/treatment/${}",
            indexUpdateTreatments:
                "/treatments/index-update/patients_treatments/id/${}",
            indexUpdateConvenios: "/convenios/index-update/treatments/id/${}",
            indexUpdateCategories: "/categories/index-update/treatments/id/${}",
        },
        evolutions: {
            index: "/evolutions/${}",
            create: "/register/evolution",
            update: "/edit/evolution/${}",
        },
        budgets: {
            index: "/budgets/${}",
            indexUpdateTreatments:
                "/treatments/index-update/budgets_treatments/budget_id/${}",
            create: "/register/budget/${}",
            update: "/edit/budget/${}",
            approve: "/approve/budget/${}",
        },
        convenios: {
            index: "/convenios",
            indexCreate: "/convenios/index-create",
            create: "/register/convenio",
            update: "/edit/convenio/${}",
        },
        anamneses: {
            index: "/anamneses/${}",
            indexAnswers: "/anamnese-answers/${}",
            indexRecentAnswersAttention: "/anamneseWithAttention/${}",
            indexQuestionsEdit: "/anamnese-questions-edit/${}",
            indexQuestionsAnswers: "/anamnese-questions-answers/${}",
            create: "/register/anamnese/${}",
            update: "/edit/anamnese/${}",
        },
        anamneseQuestions: {
            index: "/anamnese-questions",
            indexActive: "/anamnese-questions-active",
            create: "/register/anamnese-question",
            update: "/edit/anamnese-question/${}",
        },
        logs: {
            index: "/logs",
        },
    },
    forms: {
        account: [
            {
                formItemName: "name",
                formItemLabel: "Nome",
                formItemRules: [
                    {
                        required: true,
                        message: "Insira o nome!",
                    },
                ],
                formType: "input",
                formPlaceholder: "Nome",
            },
            {
                formItemName: "cnpj",
                formItemLabel: "CNPJ",
                formItemRules: [
                    {
                        required: true,
                        message: "Insira o CNPJ!",
                    },
                    ({}) => ({
                        validator(_, value) {
                            if (cnpj.isValid(value)) {
                                return Promise.resolve();
                            }
                            if (value === "" || value === undefined) {
                                return Promise.reject();
                            }
                            return Promise.reject(new Error("CNPJ Inválido!"));
                        },
                    }),
                ],
                formType: "mask",
                formInputMask: "00.000.000/0000-00",
            },
            {
                formItemLabel: "Horário de funcionamento",
                formItemName: "timerange",
                formItemRules: [
                    {
                        required: true,
                        message: "Insira o horário de agendamento!",
                    },
                ],
                formTimeRangeOnly: true,
                formType: "timePicker",
                formPlaceholder: ["Horário início", "Horário fim"],
            },
        ],
        users: {
            create: [
                {
                    formItemName: "name",
                    formItemLabel: "Nome",
                    formItemRules: [
                        {
                            required: true,
                            message: "Insira o nome!",
                        },
                    ],
                    formType: "input",
                    formPlaceholder: "Digitar nome",
                },
                {
                    formItemName: "email",
                    formItemLabel: "Email",
                    formItemRules: [
                        {
                            required: true,
                            message: "Insira o email!",
                        },
                    ],
                    formType: "input",
                    formPlaceholder: "Digitar email",
                },
                {
                    formItemName: "username",
                    formItemLabel: "Usuário",
                    formItemRules: [
                        {
                            required: true,
                            message: "Insira o usuário!",
                        },
                    ],
                    formType: "input",
                    formPlaceholder: "Digitar usuário",
                },
                {
                    formItemName: "role",
                    formItemLabel: "Perfil",
                    formItemRules: [
                        {
                            required: true,
                            message: "Selecione um perfil!",
                        },
                    ],
                    formType: "select",
                    formPlaceholder: "Selecionar perfil",
                    formSelectOptions: [
                        {
                            value: "ADMIN_DENTIST",
                            label: "Admin. e Dentista",
                        },
                        {
                            value: "ADMIN",
                            label: "Administrador",
                        },

                        {
                            value: "DENTIST",
                            label: "Dentista",
                        },
                        {
                            value: "RECEPTIONIST",
                            label: "Recepcionista",
                        },
                    ],
                },
                {
                    formItemName: "password",
                    formItemLabel: "Senha",
                    formItemRules: [
                        {
                            required: true,
                            message: "",
                        },
                        { validator: validatePasswordCreate },
                    ],
                    formItemDependencies: ["password_confirmation"],
                    formType: "password",
                    formPlaceholder: "Digitar senha",
                },
                {
                    formItemName: "password_confirmation",
                    formItemLabel: "Repetir senha",
                    formItemRules: [
                        {
                            required: true,
                            message: "Repita a senha!",
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (
                                    !value ||
                                    getFieldValue("password") === value
                                ) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(
                                    new Error("As senhas não correspondem!")
                                );
                            },
                        }),
                    ],
                    formItemDependencies: ["password"],
                    formType: "password",
                    formPlaceholder: "Digitar senha",
                },
            ],
            edit: [
                {
                    formItemName: "name",
                    formItemLabel: "Nome",
                    formItemRules: [
                        {
                            required: true,
                            message: "Insira o nome!",
                        },
                    ],
                    formType: "input",
                    formPlaceholder: "Digitar nome",
                },
                {
                    formItemName: "email",
                    formItemLabel: "Email",
                    formItemRules: [
                        {
                            required: true,
                            message: "Insira o email!",
                        },
                    ],
                    formType: "input",
                    formPlaceholder: "Digitar email",
                },
                {
                    formItemName: "username",
                    formItemLabel: "Usuário",
                    formItemRules: [
                        {
                            required: true,
                            message: "Insira o usuário!",
                        },
                    ],
                    formType: "input",
                    formPlaceholder: "Digitar usuário",
                },
                {
                    formItemName: "role",
                    formItemLabel: "Perfil",
                    formItemRules: [
                        {
                            required: true,
                            message: "Selecione um perfil!",
                        },
                    ],
                    formType: "select",
                    formPlaceholder: "Selecionar perfil",
                    formSelectOptions: [
                        {
                            value: "ADMIN_DENTIST",
                            label: "Admin. e Dentista",
                        },
                        {
                            value: "ADMIN",
                            label: "Administrador",
                        },

                        {
                            value: "DENTIST",
                            label: "Dentista",
                        },
                        {
                            value: "RECEPTIONIST",
                            label: "Recepcionista",
                        },
                    ],
                },
                {
                    formItemName: "current_password",
                    formItemRules: [
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (
                                    (value === "" || value === undefined) &&
                                    (getFieldValue("new_password") === "" ||
                                        getFieldValue("new_password") ===
                                            undefined) &&
                                    (getFieldValue(
                                        "new_password_confirmation"
                                    ) === "" ||
                                        getFieldValue(
                                            "new_password_confirmation"
                                        ) === undefined)
                                ) {
                                    return Promise.resolve();
                                } else if (
                                    value !== "" &&
                                    getFieldValue("new_password") !== "" &&
                                    getFieldValue("new_password") !==
                                        undefined &&
                                    getFieldValue(
                                        "new_password_confirmation"
                                    ) !== "" &&
                                    getFieldValue(
                                        "new_password_confirmation"
                                    ) !== undefined
                                ) {
                                    return Promise.resolve();
                                }

                                return Promise.reject(
                                    new Error(
                                        "Todos os campos de senha devem ser preenchidos!"
                                    )
                                );
                            },
                        }),
                    ],
                    formItemDependencies: [
                        "new_password",
                        "new_password_confirmation",
                    ],
                    formType: "password",
                    formPlaceholder: "Senha atual",
                },
                {
                    formItemName: "new_password",
                    formItemRules: [
                        { validator: validatePasswordEdit },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (
                                    (getFieldValue("current_password") === "" ||
                                        getFieldValue("current_password") ===
                                            undefined) &&
                                    (value === "" || value === undefined) &&
                                    (getFieldValue(
                                        "new_password_confirmation"
                                    ) === "" ||
                                        getFieldValue(
                                            "new_password_confirmation"
                                        ) === undefined)
                                ) {
                                    return Promise.resolve();
                                } else if (
                                    getFieldValue("current_password") !== "" &&
                                    getFieldValue("current_password") !==
                                        undefined &&
                                    value !== "" &&
                                    value !== undefined &&
                                    getFieldValue(
                                        "new_password_confirmation"
                                    ) !== "" &&
                                    getFieldValue(
                                        "new_password_confirmation"
                                    ) !== undefined
                                ) {
                                    if (
                                        !value ||
                                        getFieldValue("current_password") !==
                                            value
                                    ) {
                                        return Promise.resolve();
                                    } else {
                                        return Promise.reject(
                                            new Error(
                                                "A nova senha não pode ser igual a senha atual!"
                                            )
                                        );
                                    }
                                }

                                return Promise.reject(
                                    new Error(
                                        "Todos os campos de senha devem ser preenchidos!"
                                    )
                                );
                            },
                        }),
                    ],
                    formItemDependencies: [
                        "current_password",
                        "new_password_confirmation",
                    ],
                    formType: "password",
                    formPlaceholder: "Nova senha",
                },
                {
                    formItemName: "new_password_confirmation",
                    formItemRules: [
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (
                                    (getFieldValue("current_password") === "" ||
                                        getFieldValue("current_password") ===
                                            undefined) &&
                                    (getFieldValue("new_password") === "" ||
                                        getFieldValue("new_password") ===
                                            undefined) &&
                                    (value === "" || value === undefined)
                                ) {
                                    return Promise.resolve();
                                } else if (
                                    getFieldValue("current_password") !== "" &&
                                    getFieldValue("current_password") !==
                                        undefined &&
                                    getFieldValue("new_password") !== "" &&
                                    getFieldValue("new_password") !==
                                        undefined &&
                                    value !== "" &&
                                    value !== undefined
                                ) {
                                    if (
                                        !value ||
                                        getFieldValue("new_password") === value
                                    ) {
                                        return Promise.resolve();
                                    } else {
                                        return Promise.reject(
                                            new Error(
                                                "As senhas não correspondem!"
                                            )
                                        );
                                    }
                                }
                                return Promise.reject(
                                    new Error(
                                        "Todos os campos de senha devem ser preenchidos!"
                                    )
                                );
                            },
                        }),
                    ],
                    formItemDependencies: ["current_password", "new_password"],
                    formType: "password",
                    formPlaceholder: "Repetir nova senha",
                },
            ],
        },
        patients: {
            single: [
                {
                    formType: "uploadAvatar",
                },
                {
                    formItemName: "name",
                    formItemLabel: "Nome",
                    formItemRules: [
                        {
                            required: true,
                            message: "Insira o nome!",
                        },
                    ],
                    formType: "input",
                    formPlaceholder: "Digitar nome",
                },
                {
                    formItemName: "cpf",
                    formItemLabel: "CPF",
                    formItemRules: [
                        ({}) => ({
                            validator(_, value) {
                                if (
                                    value === undefined ||
                                    value === null ||
                                    value === "___.___.___-__"
                                ) {
                                    return Promise.resolve();
                                }
                                if (cpf.isValid(value)) {
                                    return Promise.resolve();
                                }
                                if (value === "" || value === undefined) {
                                    return Promise.reject();
                                }
                                return Promise.reject(
                                    new Error("CPF Inválido!")
                                );
                            },
                        }),
                    ],
                    formType: "mask",
                    formInputMask: "000.000.000-00",
                },
                {
                    formItemName: "responsible_name",
                    formItemLabel: "Nome do responsável",
                    formType: "input",
                    formPlaceholder: "Digitar nome",
                },
                {
                    formItemName: "responsible_cpf",
                    formItemLabel: "CPF do responsável",
                    formItemRules: [
                        ({}) => ({
                            validator(_, value) {
                                if (
                                    value === undefined ||
                                    value === null ||
                                    value === "___.___.___-__"
                                ) {
                                    return Promise.resolve();
                                }
                                if (cpf.isValid(value)) {
                                    return Promise.resolve();
                                }
                                if (value === "" || value === undefined) {
                                    return Promise.reject();
                                }
                                return Promise.reject(
                                    new Error("CPF Inválido!")
                                );
                            },
                        }),
                    ],
                    formType: "mask",
                    formInputMask: "000.000.000-00",
                },
                {
                    formItemName: "email",
                    formItemLabel: "Email",
                    formType: "input",
                    formPlaceholder: "Digitar email",
                },
                {
                    formItemName: "address",
                    formItemLabel: "Endereço",
                    formType: "input",
                    formPlaceholder: "Digitar endereço",
                },
                {
                    formItemName: "phone",
                    formItemLabel: "Telefone",
                    formType: "mask",
                    formInputMask: "(00) 00000-0000",
                },
                {
                    formItemName: "birth",
                    formItemLabel: "Data de nascimento",
                    formType: "date",
                    formDisabledDate: "future",
                },
                {
                    formItemName: "occupation",
                    formItemLabel: "Profissão",
                    formType: "input",
                    formPlaceholder: "Digitar profissão",
                },
                {
                    formItemName: "gender",
                    formItemLabel: "Gênero",
                    formType: "select",
                    formPlaceholder: "Selecionar gênero",
                    formSelectOptions: [
                        {
                            value: "Masculino",
                            label: "Masculino",
                        },
                        {
                            value: "Feminino",
                            label: "Feminino",
                        },
                    ],
                },
                {
                    formItemName: "convenio_ids",
                    formItemLabel: "Convênio",
                    formPlaceholder: "Selecionar convênio",
                    formSelectMode: "multiple",
                    formType: "searchInput",
                    formSelectEntity: "convenios",
                },
                {
                    formItemName: "note",
                    formType: "textArea",
                    formPlaceholder: "Notas",
                },
            ],
        },
        schedules: {
            createWithDentist: [
                {
                    formItemName: "patient_id",
                    formItemRules: [
                        {
                            required: true,
                            message: "Selecione um paciente!",
                        },
                    ],
                    formType: "searchInput",
                    formPlaceholder: "Selecionar paciente",
                    formSelectEntity: "patients",
                },
                {
                    formItemName: "user_id",
                    formItemRules: [
                        {
                            required: true,
                            message: "Selecione um dentista!",
                        },
                    ],
                    formType: "searchInput",
                    formPlaceholder: "Selecionar dentista",
                    formSelectEntity: "dentists",
                },
                {
                    formItemName: "date",
                    formItemRules: [
                        {
                            required: true,
                            message: "Insira a data do agendamento!",
                        },
                    ],
                    formType: "date",
                    formPlaceholder: "Data de agendamento",
                    //formDisabledDate: "past",
                },
                {
                    formItemName: "timerange",
                    formItemRules: [
                        {
                            required: true,
                            message: "Insira o horário de agendamento!",
                        },
                    ],
                    formType: "timePicker",
                    formPlaceholder: ["Horário início", "Horário fim"],
                },
                {
                    formItemName: "categories_ids",
                    formSelectMode: "multiple",
                    formType: "searchInput",
                    formPlaceholder: "Selecionar categoria",
                    formSelectEntity: "categories",
                },
                {
                    formItemName: "note",
                    formType: "textArea",
                    formPlaceholder: "Notas",
                },
                {
                    formItemName: "is_anticipated",
                    formType: "radio",
                    formItemRules: [
                        {
                            required: true,
                            message: "Selecione uma opção!",
                        },
                    ],
                    formItemLabel: "É antecipável?",
                    formPlaceholder: "Notas",
                },
            ],
            createWithoutDentist: [
                {
                    formItemName: "patient_id",
                    formItemRules: [
                        {
                            required: true,
                            message: "Selecione um paciente!",
                        },
                    ],
                    formType: "searchInput",
                    formPlaceholder: "Selecionar paciente",
                    formSelectEntity: "patients",
                },
                {
                    formItemName: "date",
                    formItemRules: [
                        {
                            required: true,
                            message: "Insira a data do agendamento!",
                        },
                    ],
                    formType: "date",
                    formPlaceholder: "Data de agendamento",
                    //formDisabledDate: "past",
                },
                {
                    formItemName: "timerange",
                    formItemRules: [
                        {
                            required: true,
                            message: "Insira o horário de agendamento!",
                        },
                    ],
                    formType: "timePicker",
                    formPlaceholder: ["Horário início", "Horário fim"],
                },
                {
                    formItemName: "categories_ids",
                    formSelectMode: "multiple",
                    formType: "searchInput",
                    formPlaceholder: "Selecionar categoria",
                    formSelectEntity: "categories",
                },
                {
                    formItemName: "note",
                    formType: "textArea",
                    formPlaceholder: "Notas",
                },
                {
                    formItemName: "is_anticipated",
                    formType: "radio",
                    formItemRules: [
                        {
                            required: true,
                            message: "Selecione uma opção!",
                        },
                    ],
                    formItemLabel: "É antecipável?",
                    formPlaceholder: "Notas",
                },
            ],
            editWithDentist: [
                {
                    formItemName: "patient_id",
                    formItemRules: [
                        {
                            required: true,
                            message: "Selecione um paciente!",
                        },
                    ],
                    formType: "searchInput",
                    formPlaceholder: "Selecionar paciente",
                    formSelectEntity: "patients",
                },
                {
                    formItemName: "user_id",
                    formItemRules: [
                        {
                            required: true,
                            message: "Selecione um dentista!",
                        },
                    ],
                    formType: "searchInput",
                    formPlaceholder: "Selecionar dentista",
                    formSelectEntity: "dentists",
                },
                {
                    formItemName: "date",
                    formItemRules: [
                        {
                            required: true,
                            message: "Insira a data do agendamento!",
                        },
                    ],
                    formType: "date",
                    formPlaceholder: "Data de agendamento",
                    formDisabledDate: "past",
                },
                {
                    formItemName: "timerange",
                    formItemRules: [
                        {
                            required: true,
                            message: "Insira o horário de agendamento!",
                        },
                    ],
                    formType: "timePicker",
                    formPlaceholder: ["Horário início", "Horário fim"],
                },
                {
                    formItemName: "categories_ids",
                    formSelectMode: "multiple",
                    formType: "searchInput",
                    formPlaceholder: "Selecionar categoria",
                    formSelectEntity: "categories",
                },
                {
                    formItemName: "status",
                    formItemRules: [
                        {
                            required: true,
                            message: "Selecione um status!",
                        },
                    ],
                    formType: "select",
                    formPlaceholder: "Status",
                    formSelectOptions: [
                        {
                            value: "agendado",
                            label: "Agendado",
                        },
                        {
                            value: "confirmado",
                            label: "Confirmado",
                        },
                        {
                            value: "faltou",
                            label: "Faltou",
                        },
                        {
                            value: "desmarcado_clinica",
                            label: "Desmarcado pela clínica",
                        },

                        {
                            value: "desmarcado_paciente",
                            label: "Desmarcado pelo paciente",
                        },
                    ],
                },
                {
                    formItemName: "note",
                    formType: "textArea",
                    formPlaceholder: "Notas",
                },
                {
                    formItemName: "is_anticipated",
                    formType: "radio",
                    formItemRules: [
                        {
                            required: true,
                            message: "Selecione uma opção!",
                        },
                    ],
                    formItemLabel: "É antecipável?",
                    formPlaceholder: "Notas",
                },
            ],
            editWithoutDentist: [
                {
                    formItemName: "patient_id",
                    formItemRules: [
                        {
                            required: true,
                            message: "Selecione um paciente!",
                        },
                    ],
                    formType: "searchInput",
                    formPlaceholder: "Selecionar paciente",
                    formSelectEntity: "patients",
                },
                {
                    formItemName: "date",
                    formItemRules: [
                        {
                            required: true,
                            message: "Insira a data do agendamento!",
                        },
                    ],
                    formType: "date",
                    formPlaceholder: "Data de agendamento",
                    formDisabledDate: "past",
                },
                {
                    formItemName: "timerange",
                    formItemRules: [
                        {
                            required: true,
                            message: "Insira o horário de agendamento!",
                        },
                    ],
                    formType: "timePicker",
                    formPlaceholder: ["Horário início", "Horário fim"],
                },
                {
                    formItemName: "categories_ids",
                    formSelectMode: "multiple",
                    formType: "searchInput",
                    formPlaceholder: "Selecionar categoria",
                    formSelectEntity: "categories",
                },
                {
                    formItemName: "status",
                    formItemRules: [
                        {
                            required: true,
                            message: "Selecione um status!",
                        },
                    ],
                    formType: "select",
                    formPlaceholder: "Status",
                    formSelectOptions: [
                        {
                            value: "agendado",
                            label: "Agendado",
                        },
                        {
                            value: "confirmado",
                            label: "Confirmado",
                        },
                        {
                            value: "faltou",
                            label: "Faltou",
                        },
                        {
                            value: "desmarcado_clinica",
                            label: "Desmarcado pela clínica",
                        },

                        {
                            value: "desmarcado_paciente",
                            label: "Desmarcado pelo paciente",
                        },
                    ],
                },
                {
                    formItemName: "note",
                    formType: "textArea",
                    formPlaceholder: "Notas",
                },
                {
                    formItemName: "is_anticipated",
                    formType: "radio",
                    formItemRules: [
                        {
                            required: true,
                            message: "Selecione uma opção!",
                        },
                    ],
                    formItemLabel: "É antecipável?",
                    formPlaceholder: "Notas",
                },
            ],
        },
        budgets: {
            single: [
                {
                    formItemName: "treatments_ids",
                    formItemRules: [
                        {
                            required: true,
                            message: "Selecione um tratamento!",
                        },
                    ],
                    formType: "searchInput",
                    formSelectPrimary: true,
                    formPlaceholder: "Selecionar tratamento",
                    formSelectEntity: "treatments",
                },
                {
                    formItemInitialValue: { value: 0, type: "%" },
                    formItemName: "discount",
                    formItemLabel: "Desconto",
                    formItemRules: [
                        {
                            required: true,
                            message: "Insira um desconto!",
                        },
                    ],
                    formType: "selectBeforeInput",
                },
            ],
        },
        treatments: {
            single: [
                {
                    formItemName: "name",
                    formItemLabel: "Nome",
                    formItemRules: [
                        {
                            required: true,
                            message: "Insira o nome!",
                        },
                    ],
                    formType: "input",
                    formPlaceholder: "Nome",
                },
                {
                    formItemName: "value",
                    formItemLabel: "Valor",
                    formItemRules: [
                        {
                            required: true,
                            message: "Insira o valor!",
                        },
                    ],
                    formType: "inputCurrency",
                    formInputAddonBefore: "R$",
                },
                {
                    formItemName: "convenio_id",
                    formItemLabel: "Convênio",
                    formItemRules: [
                        {
                            required: true,
                            message: "Selecione um convênio!",
                        },
                    ],
                    formType: "searchInput",
                    formPlaceholder: "Selecionar convênio",
                    formSelectEntity: "convenios",
                },
                {
                    formItemName: "category_id",
                    formItemLabel: "Categoria",
                    formItemRules: [
                        {
                            required: true,
                            message: "Selecione uma categoria!",
                        },
                    ],
                    formType: "searchInput",
                    formPlaceholder: "Selecionar categoria",
                    formSelectEntity: "categories",
                },
            ],
            patientTreatmentsWithDentist: [
                {
                    formItemName: "dentist_id",
                    formItemRules: [
                        {
                            required: true,
                            message: "Selecione um dentista!",
                        },
                    ],
                    formType: "searchInput",
                    formPlaceholder: "Selecionar dentista",
                    formSelectEntity: "dentists",
                },
                {
                    formItemName: "treatment_id",
                    formItemRules: [
                        {
                            required: true,
                            message: "Selecione um tratamento!",
                        },
                    ],
                    formType: "searchInput",
                    formPlaceholder: "Selecionar tratamento",
                    formSelectEntity: "treatments",
                },
                {
                    formItemInitialValue: { value: 0, type: "%" },
                    formItemName: "discount",
                    formItemLabel: "Desconto",
                    formItemRules: [
                        {
                            required: true,
                            message: "Insira um desconto!",
                        },
                    ],
                    formType: "selectBeforeInput",
                },
                {
                    formItemName: "quantity",
                    formItemLabel: "Quantidade",
                    formItemRules: [
                        {
                            required: true,
                            message: "Insira uma quantidade!",
                        },
                    ],
                    formType: "inputNumber",
                },
            ],
            patientTreatmentsWithoutDentist: [
                {
                    formItemName: "treatment_id",
                    formItemRules: [
                        {
                            required: true,
                            message: "Selecione um tratamento!",
                        },
                    ],
                    formType: "searchInput",
                    formPlaceholder: "Selecionar tratamento",
                    formSelectEntity: "treatments",
                },
                {
                    formItemInitialValue: { value: 0, type: "%" },
                    formItemName: "discount",
                    formItemLabel: "Desconto",
                    formItemRules: [
                        {
                            required: true,
                            message: "Insira um desconto!",
                        },
                    ],
                    formType: "selectBeforeInput",
                },
            ],
        },
        evolutions: {
            evolutionsWithDentist: [
                {
                    formItemName: "user_id",
                    formItemRules: [
                        {
                            required: true,
                            message: "Selecione um dentista!",
                        },
                    ],
                    formType: "searchInput",
                    formPlaceholder: "Selecionar dentista",
                    formSelectEntity: "dentists",
                },
                {
                    formItemName: "date",
                    formItemRules: [
                        {
                            required: true,
                            message: "Insira a data da evolução!",
                        },
                    ],
                    formType: "date",
                    formPlaceholder: "Data da evolução",
                    formDisabledDate: "future",
                },
                {
                    formItemName: "description",
                    formType: "textArea",
                    formItemRules: [
                        {
                            required: true,
                            message: "Descreva a evolução!",
                        },
                    ],
                    formPlaceholder:
                        "Descreva aqui a evolução do tratamento do paciente.",
                },
            ],
            evolutionsWithoutDentist: [
                {
                    formItemName: "date",
                    formItemRules: [
                        {
                            required: true,
                            message: "Insira a data da evolução!",
                        },
                    ],
                    formType: "date",
                    formPlaceholder: "Data da evolução",
                    formDisabledDate: "future",
                },
                {
                    formItemName: "description",
                    formType: "textArea",
                    formPlaceholder:
                        "Descreva aqui a evolução do tratamento do paciente.",
                },
            ],
        },
        evolutionsFloat: {
            evolutionsWithDentist: [
                {
                    formItemName: "patient_id",
                    formItemRules: [
                        {
                            required: true,
                            message: "Selecione um paciente!",
                        },
                    ],
                    formType: "searchInput",
                    formPlaceholder: "Selecionar paciente",
                    formSelectEntity: "patients",
                },
                {
                    formItemName: "user_id",
                    formItemRules: [
                        {
                            required: true,
                            message: "Selecione um dentista!",
                        },
                    ],
                    formType: "searchInput",
                    formPlaceholder: "Selecionar dentista",
                    formSelectEntity: "dentists",
                },
                {
                    formItemName: "date",
                    formItemRules: [
                        {
                            required: true,
                            message: "Insira a data da evolução!",
                        },
                    ],
                    formType: "date",
                    formPlaceholder: "Data da evolução",
                    formDisabledDate: "future",
                },
                {
                    formItemName: "description",
                    formType: "textArea",
                    formItemRules: [
                        {
                            required: true,
                            message: "Descreva a evolução!",
                        },
                    ],
                    formPlaceholder:
                        "Descreva aqui a evolução do tratamento do paciente.",
                },
            ],
            evolutionsWithoutDentist: [
                {
                    formItemName: "patient_id",
                    formItemRules: [
                        {
                            required: true,
                            message: "Selecione um paciente!",
                        },
                    ],
                    formType: "searchInput",
                    formPlaceholder: "Selecionar paciente",
                    formSelectEntity: "patients",
                },
                {
                    formItemName: "date",
                    formItemRules: [
                        {
                            required: true,
                            message: "Insira a data da evolução!",
                        },
                    ],
                    formType: "date",
                    formPlaceholder: "Data da evolução",
                    formDisabledDate: "future",
                },
                {
                    formItemName: "description",
                    formType: "textArea",
                    formPlaceholder:
                        "Descreva aqui a evolução do tratamento do paciente.",
                },
            ],
        },
        discounts: {
            createDiscount: [
                {
                    formItemName: "name",
                    formItemRules: [
                        {
                            required: true,
                            message: "Insira o nome!",
                        },
                    ],
                    formType: "input",
                    formPlaceholder: "Nome",
                },
                {
                    formItemName: "value",
                    formItemRules: [
                        {
                            required: true,
                            message: "Insira o valor!",
                        },
                    ],
                    formType: "inputNumber",
                    formInputAddonBefore: "%",
                    formNumberMin: 0,
                    formNumberMax: 100,
                },
            ],
        },
        convenios: {
            createConvenio: [
                {
                    formItemName: "name",
                    formItemRules: [
                        {
                            required: true,
                            message: "Insira o nome!",
                        },
                    ],
                    formType: "input",
                    formPlaceholder: "Nome",
                },
                {
                    formItemName: "color",
                    formItemRules: [
                        {
                            required: true,
                            message: "Selecione uma cor!",
                        },
                    ],
                    formType: "select",
                    formPlaceholder: "Cor",
                    formSelectOptions: [
                        {
                            value: "blue",
                            label: (
                                <Flex align="center" gap="small">
                                    <ColorPicker
                                        value="#0958d9"
                                        showText={false}
                                        open={false}
                                        size="small"
                                        style={{ cursor: "inherit" }}
                                    />
                                    Azul
                                </Flex>
                            ),
                        },
                        {
                            value: "red",
                            label: (
                                <Flex align="center" gap="small">
                                    <ColorPicker
                                        value="#cf1322"
                                        showText={false}
                                        open={false}
                                        size="small"
                                        style={{ cursor: "inherit" }}
                                    />
                                    Vermelho
                                </Flex>
                            ),
                        },
                        {
                            value: "orange",
                            label: (
                                <Flex align="center" gap="small">
                                    <ColorPicker
                                        value="#d46b08"
                                        showText={false}
                                        open={false}
                                        size="small"
                                        style={{ cursor: "inherit" }}
                                    />
                                    Laranja
                                </Flex>
                            ),
                        },
                        {
                            value: "gold",
                            label: (
                                <Flex align="center" gap="small">
                                    <ColorPicker
                                        value="#d48806"
                                        showText={false}
                                        open={false}
                                        size="small"
                                        style={{ cursor: "inherit" }}
                                    />
                                    Dourado
                                </Flex>
                            ),
                        },
                        {
                            value: "magenta",
                            label: (
                                <Flex align="center" gap="small">
                                    <ColorPicker
                                        value="#c41d7f"
                                        showText={false}
                                        open={false}
                                        size="small"
                                        style={{ cursor: "inherit" }}
                                    />
                                    Magenta
                                </Flex>
                            ),
                        },
                        {
                            value: "geekblue",
                            label: (
                                <Flex align="center" gap="small">
                                    <ColorPicker
                                        value="#1d39c4"
                                        showText={false}
                                        open={false}
                                        size="small"
                                        style={{ cursor: "inherit" }}
                                    />
                                    Azul Geeks
                                </Flex>
                            ),
                        },
                        {
                            value: "volcano",
                            label: (
                                <Flex align="center" gap="small">
                                    <ColorPicker
                                        value="#d4380d"
                                        showText={false}
                                        open={false}
                                        size="small"
                                        style={{ cursor: "inherit" }}
                                    />
                                    Vulcão
                                </Flex>
                            ),
                        },
                        {
                            value: "cyan",
                            label: (
                                <Flex align="center" gap="small">
                                    <ColorPicker
                                        value="#08979c"
                                        showText={false}
                                        open={false}
                                        size="small"
                                        style={{ cursor: "inherit" }}
                                    />
                                    Ciano
                                </Flex>
                            ),
                        },
                        {
                            value: "lime",
                            label: (
                                <Flex align="center" gap="small">
                                    <ColorPicker
                                        value="#7cb305"
                                        showText={false}
                                        open={false}
                                        size="small"
                                        style={{ cursor: "inherit" }}
                                    />
                                    Limão
                                </Flex>
                            ),
                        },
                        {
                            value: "green",
                            label: (
                                <Flex align="center" gap="small">
                                    <ColorPicker
                                        value="#389e0d"
                                        showText={false}
                                        open={false}
                                        size="small"
                                        style={{ cursor: "inherit" }}
                                    />
                                    Verde
                                </Flex>
                            ),
                        },
                        {
                            value: "purple",
                            label: (
                                <Flex align="center" gap="small">
                                    <ColorPicker
                                        value="#531dab"
                                        showText={false}
                                        open={false}
                                        size="small"
                                        style={{ cursor: "inherit" }}
                                    />
                                    Roxo
                                </Flex>
                            ),
                        },
                    ],
                },
            ],
        },
        categories: {
            single: [
                {
                    formItemName: "name",
                    formItemRules: [
                        {
                            required: true,
                            message: "Insira o nome!",
                        },
                    ],
                    formType: "input",
                    formPlaceholder: "Nome",
                },
                {
                    formItemName: "color",
                    formItemRules: [
                        {
                            required: true,
                            message: "Selecione uma cor!",
                        },
                    ],
                    formType: "select",
                    formPlaceholder: "Cor",
                    formSelectOptions: [
                        {
                            value: "blue",
                            label: (
                                <Flex align="center" gap="small">
                                    <ColorPicker
                                        value="#0958d9"
                                        showText={false}
                                        open={false}
                                        size="small"
                                        style={{ cursor: "inherit" }}
                                    />
                                    Azul
                                </Flex>
                            ),
                        },
                        {
                            value: "red",
                            label: (
                                <Flex align="center" gap="small">
                                    <ColorPicker
                                        value="#cf1322"
                                        showText={false}
                                        open={false}
                                        size="small"
                                        style={{ cursor: "inherit" }}
                                    />
                                    Vermelho
                                </Flex>
                            ),
                        },
                        {
                            value: "orange",
                            label: (
                                <Flex align="center" gap="small">
                                    <ColorPicker
                                        value="#d46b08"
                                        showText={false}
                                        open={false}
                                        size="small"
                                        style={{ cursor: "inherit" }}
                                    />
                                    Laranja
                                </Flex>
                            ),
                        },
                        {
                            value: "gold",
                            label: (
                                <Flex align="center" gap="small">
                                    <ColorPicker
                                        value="#d48806"
                                        showText={false}
                                        open={false}
                                        size="small"
                                        style={{ cursor: "inherit" }}
                                    />
                                    Dourado
                                </Flex>
                            ),
                        },
                        {
                            value: "magenta",
                            label: (
                                <Flex align="center" gap="small">
                                    <ColorPicker
                                        value="#c41d7f"
                                        showText={false}
                                        open={false}
                                        size="small"
                                        style={{ cursor: "inherit" }}
                                    />
                                    Magenta
                                </Flex>
                            ),
                        },
                        {
                            value: "geekblue",
                            label: (
                                <Flex align="center" gap="small">
                                    <ColorPicker
                                        value="#1d39c4"
                                        showText={false}
                                        open={false}
                                        size="small"
                                        style={{ cursor: "inherit" }}
                                    />
                                    Azul Geeks
                                </Flex>
                            ),
                        },
                        {
                            value: "volcano",
                            label: (
                                <Flex align="center" gap="small">
                                    <ColorPicker
                                        value="#d4380d"
                                        showText={false}
                                        open={false}
                                        size="small"
                                        style={{ cursor: "inherit" }}
                                    />
                                    Vulcão
                                </Flex>
                            ),
                        },
                        {
                            value: "cyan",
                            label: (
                                <Flex align="center" gap="small">
                                    <ColorPicker
                                        value="#08979c"
                                        showText={false}
                                        open={false}
                                        size="small"
                                        style={{ cursor: "inherit" }}
                                    />
                                    Ciano
                                </Flex>
                            ),
                        },
                        {
                            value: "lime",
                            label: (
                                <Flex align="center" gap="small">
                                    <ColorPicker
                                        value="#7cb305"
                                        showText={false}
                                        open={false}
                                        size="small"
                                        style={{ cursor: "inherit" }}
                                    />
                                    Limão
                                </Flex>
                            ),
                        },
                        {
                            value: "green",
                            label: (
                                <Flex align="center" gap="small">
                                    <ColorPicker
                                        value="#389e0d"
                                        showText={false}
                                        open={false}
                                        size="small"
                                        style={{ cursor: "inherit" }}
                                    />
                                    Verde
                                </Flex>
                            ),
                        },
                        {
                            value: "purple",
                            label: (
                                <Flex align="center" gap="small">
                                    <ColorPicker
                                        value="#531dab"
                                        showText={false}
                                        open={false}
                                        size="small"
                                        style={{ cursor: "inherit" }}
                                    />
                                    Roxo
                                </Flex>
                            ),
                        },
                    ],
                },
            ],
        },
        anamneseQuestions: {
            single: [
                {
                    formItemName: "name",
                    formItemRules: [
                        {
                            required: true,
                            message: "Insira a pergunta!",
                        },
                    ],
                    formType: "input",
                    formPlaceholder:
                        "Exemplo: Usa alguma medicação controlada?",
                },
                {
                    formItemName: "answer_type",
                    formItemInitialValue: 1,
                    formItemRules: [
                        {
                            required: true,
                            message: "Selecione um tipo de resposta!",
                        },
                    ],
                    formType: "select",
                    formSelectOptions: [
                        {
                            value: 1,
                            label: "Sim / Não",
                        },
                        {
                            value: 2,
                            label: "Sim / Não e Texto",
                        },
                        {
                            value: 3,
                            label: "Somente texto",
                        },
                    ],
                },
            ],
        },
    },
    localeTable: {
        filterTitle: "Menu de Filtro",
        filterConfirm: "OK",
        filterReset: "Resetar",
        filterEmptyText: "Sem filtros",
        emptyText: "Nenhum dado disponível",
        selectAll: "Selecionar página atual",
        selectInvert: "Inverter seleção",
        selectNone: "Apagar todo o conteúdo",
        selectionAll: "Selecionar todo o conteúdo",
        sortTitle: "Ordenar título",
        expand: "Expandir linha",
        collapse: "Colapsar linha",
        triggerDesc: "Clique organiza por descendente",
        triggerAsc: "Clique organiza por ascendente",
        cancelSort: "Clique para cancelar organização",
    },
};

export default pattern;

/* {
                    formItemName: "procedure_id",
                    formItemRules: [
                        {
                            required: true,
                            message: "Selecione um procedimento!",
                        },
                    ],
                    formType: "searchInput",
                    formSelectPrimary: true,
                    formPlaceholder: "Selecionar procedimento",
                    formSelectEntity: "procedures",
                },
                {
                    formItemLabel: "Versão do procedimento",
                    formItemName: "log_procedure_id",
                    formItemTooltip:
                        "O procedimento selecionado teve alteração. Selecione a versão desejada.",
                    formItemDependencies: ["procedure_id"],
                    formItemRules: [
                        {
                            required: true,
                            message: "Selecione uma versão do procedimento!",
                        },
                    ],
                    formSelectDependency: true,
                    formSelectMain: "procedure_id",
                    formType: "searchInput",
                    formPlaceholder: "Selecionar versão do procedimento",
                    formSelectEntity: "log_procedures",
                }*/
