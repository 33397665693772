import React, { useEffect, useState } from "react";
import { Button, Form, Select, InputNumber, Space, Statistic } from "antd";

const FormPatientTreatment = (props) => {
    const [totalValue, setTotalValue] = useState(props.initialValue || 0);

    const {
        form,
        name,
        role,
        onFinish,
        onFinishFailed,
        openDrawer,
        initialValue,
        dataActiveTreatments = [],
        dataActiveDentists = [],
        buttonText = "Adicionar Tratamento",
    } = props;

    useEffect(() => {
        if (!openDrawer) {
            setTotalValue(0);
        }
        if (openDrawer) {
            setTotalValue(initialValue);
        }
    }, [openDrawer]);

    const handleValuesChange = (_, allValues) => {
        if (!allValues?.treatment_id) {
            setTotalValue(0);
            return;
        }

        let newTotal = 0;

        if (!allValues?.treatment_id) return; // se não selecionou nada, pula
        const foundTreatment = dataActiveTreatments.find(
            (t) => t.id === allValues.treatment_id
        );
        if (!foundTreatment) return;

        const baseValue =
            (foundTreatment.value || 0) * (allValues.quantity || 1);
        const discount_value = allValues.discount_value || 0;
        let lineTotal = baseValue;

        if (allValues.discount_type === "R$") {
            lineTotal = baseValue - discount_value;
        } else if (allValues.discount_type === "%") {
            lineTotal = baseValue - (discount_value / 100) * baseValue;
        }

        newTotal += lineTotal > 0 ? lineTotal : 0;

        setTotalValue(newTotal);
    };

    const handleSubmit = (values) => {
        const finalPayload = {
            ...values,
            final_value: totalValue,
        };

        onFinish(finalPayload);
    };

    return (
        <Form
            form={form}
            name={name}
            layout="vertical"
            onFinish={handleSubmit}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            onValuesChange={handleValuesChange}
        >
            {role !== "DENTIST" ? (
                <Form.Item
                    name="dentist_id"
                    rules={[
                        {
                            required: true,
                            message: "Adicionar tratamento",
                        },
                    ]}
                    style={{ width: "100%" }}
                >
                    <Select placeholder="Dentista">
                        {dataActiveDentists.map((t) => (
                            <Select.Option key={t.id} value={t.id}>
                                {t.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            ) : (
                ""
            )}
            <Space
                align="baseline"
                style={{
                    display: "flex",
                    marginBottom: 8,
                    border: "1px solid #ccc",
                    padding: "10px",
                    borderRadius: 4,
                }}
            >
                <Form.Item
                    name="treatment_id"
                    rules={[
                        {
                            required: true,
                            message: "Selecione o tratamento",
                        },
                    ]}
                    style={{ width: 180 }}
                >
                    <Select placeholder="Tratamento">
                        {dataActiveTreatments.map((t) => (
                            <Select.Option key={t.id} value={t.id}>
                                {t.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    name="discount_type"
                    initialValue="%"
                    style={{ width: 60 }}
                >
                    <Select>
                        <Select.Option value="%">%</Select.Option>
                        <Select.Option value="R$">R$</Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    name="discount_value"
                    initialValue={0}
                    style={{ width: 80 }}
                >
                    <InputNumber
                        min={0}
                        placeholder="Desconto"
                        style={{ width: "100%" }}
                    />
                </Form.Item>

                <Form.Item
                    name="quantity"
                    initialValue={1}
                    rules={[
                        {
                            type: "number",
                            min: 1,
                            message: "Min 1",
                        },
                    ]}
                    style={{ width: 60 }}
                >
                    <InputNumber
                        min={1}
                        placeholder="Qtd"
                        style={{ width: "100%" }}
                    />
                </Form.Item>
            </Space>

            {/* MOSTRA O VALOR TOTAL CALCULADO */}
            <Statistic
                title="Valor final:"
                value={`R$ ${Number(totalValue).toLocaleString("pt-BR", {
                    minimumFractionDigits: 2,
                })}`}
            />
            <br />

            {/* BOTÃO DE SUBMISSÃO */}
            <Form.Item>
                <Button
                    style={{ width: "100%" }}
                    type="primary"
                    htmlType="submit"
                >
                    {buttonText}
                </Button>
            </Form.Item>
        </Form>
    );
};

export default FormPatientTreatment;
