//React
import React, { useState, useEffect, useRef } from "react";

//Context
import { PDFProvider } from "../../context/PDFProvider";

//Components
import FormAnamnese from "../FormAnamnese";
import AnamnesePdf from "../pdf/AnamnesePdf";
import ModalSignature from "../ModalSignature";

//Icons
import {
    FaFileDownload,
    FaFileSignature,
    FaEllipsisH,
    FaEdit,
    FaPrint,
} from "react-icons/fa";

//Library components
import {
    Flex,
    Table,
    Modal,
    Tag,
    Form,
    message,
    Tooltip,
    Button,
    Dropdown,
    Drawer,
} from "antd";

//Api
import axios from "../../api/axios";
import pattern from "../../api/pattern";

//Variables
const { endpoints, localeTable } = pattern;

const PatientAnamnese = (props) => {
    const [loadingAnamnese, setLoadingAnamnese] = useState(true);
    const [loadingButtonDrawer, setLoadingButtonDrawer] = useState(false);
    const [patientAnamnese, setPatientAnamnese] = useState([]);
    const countRender = useRef(0);

    const [isModalPrintAnamneseOpen, setIsModalPrintAnamneseOpen] =
        useState(false);
    const [isModalSignOpen, setIsModalSignOpen] = useState(false);

    const [idAnamnese, setIdAnamnese] = useState(null);
    const [openAnamnese, setOpenAnamnese] = useState(false);
    const [openAnamneseEdit, setOpenAnamneseEdit] = useState(false);
    const [formAnamnese] = Form.useForm();
    const [formAnamneseEdit] = Form.useForm();

    const fetchDataAnamnese = async () => {
        try {
            const response = await axios.get(
                endpoints.anamneses.index.replace("${}", props.patientId)
            );
            setPatientAnamnese(response.data);
            setLoadingAnamnese(false);
        } catch (error) {
            console.error("Erro ao obter dados:", error);
        }
    };

    useEffect(() => {
        // if (countRender.current < 1) {
        //     countRender.current += 1;
        //     return;
        // }
        if (props.isModalOpen === false) {
            setPatientAnamnese([]);
            return;
        }

        fetchDataAnamnese();
    }, [props.isModalOpen]);

    const showDrawerAnamnese = async (id) => {
        try {
            const response = await axios.get(
                endpoints.anamneses.indexAnswers.replace("${}", id)
            );
            formAnamneseEdit.setFieldsValue(response.data);
            setOpenAnamneseEdit(true);
        } catch (error) {
            console.error("Erro ao obter dados:", error);
        }
    };

    const onCloseAnamnese = () => {
        setOpenAnamnese(false);
        formAnamnese.resetFields();
    };

    const onCloseAnamneseEdit = () => {
        setOpenAnamneseEdit(false);
        formAnamneseEdit.resetFields();
    };

    const showModalPrintAnamnese = () => {
        setIsModalPrintAnamneseOpen(true);
    };
    const handleCancelPrintAnamnese = () => {
        setIsModalPrintAnamneseOpen(false);
    };

    const showModalSign = () => {
        setIsModalSignOpen(true);
    };
    const handleCancelSign = () => {
        setIsModalSignOpen(false);
    };

    const onFinishAnamnese = async (values) => {
        setLoadingButtonDrawer(true);
        try {
            await axios.post(
                endpoints.anamneses.create.replace("${}", props.patientId),
                values,
                {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                }
            );

            fetchDataAnamnese();
            setLoadingButtonDrawer(false);
            onCloseAnamnese();
            message.success("Anamnese cadastrada com sucesso!");
        } catch (error) {
            message.error("Campo(s) inválido(s)!");
        }
    };

    const onFinishAnamneseEdit = async (values) => {
        setLoadingButtonDrawer(true);
        try {
            await axios.post(
                endpoints.anamneses.update.replace("${}", idAnamnese),
                values,
                {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                }
            );

            fetchDataAnamnese();
            setLoadingButtonDrawer(false);
            onCloseAnamneseEdit();
            message.success("Anamnese atualizada com sucesso!");
        } catch (error) {
            message.error("Campo(s) inválido(s)!");
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    const columnsAnamnese = !props.isMobile
        ? [
              {
                  title: "ID",
                  dataIndex: "id",
                  key: "id",
              },
              {
                  title: "Criado",
                  dataIndex: "created_at_formatted",
                  key: "created_at_formatted",
              },
              {
                  title: "Atualizado",
                  dataIndex: "updated_at_formatted",
                  key: "updated_at_formatted",
              },
              {
                  title: "Assinado",
                  dataIndex: "signed",
                  key: "signed",
                  render: (_, { sign, signed }) => {
                      return (
                          <Tag color={sign.signed ? "green" : "red"} key={sign}>
                              {signed}
                          </Tag>
                      );
                  },
              },
              {
                  title: "Ações",
                  dataIndex: "id",
                  key: "id",
                  render: (_, { id, sign }) =>
                      sign.signed ? (
                          <Tooltip title="Documento assinado">
                              <Button
                                  onClick={() => {
                                      props.setLoadingDocSignDownload(
                                          (prev) => ({
                                              ...prev,
                                              [id]: true,
                                          })
                                      );
                                      props.handleDownloadDocumentSigned(
                                          sign.file,
                                          id
                                      );
                                  }}
                                  loading={props.loadingDocSignDownload[id]}
                                  icon={<FaFileDownload />}
                              />
                          </Tooltip>
                      ) : (
                          <Flex gap="small">
                              <Tooltip title="Assinar">
                                  <Button
                                      onClick={() => {
                                          setIdAnamnese(id);
                                          showModalSign();
                                      }}
                                      icon={<FaFileSignature />}
                                  />
                              </Tooltip>
                              <Dropdown
                                  menu={{ items: subitemsAnamnese(id) }}
                                  placement="bottom"
                                  arrow
                              >
                                  <Button icon={<FaEllipsisH />} />
                              </Dropdown>
                          </Flex>
                      ),
              },
          ]
        : [
              {
                  title: "Anamnese",
                  key: "id",
                  render: (
                      _,
                      {
                          id,
                          sign,
                          signed,
                          created_at_formatted,
                          updated_at_formatted,
                      }
                  ) => {
                      return (
                          <Flex vertical gap="small" align="start">
                              <Flex gap="small">
                                  <div>#{id} - Assinado:</div>
                                  <Tag
                                      color={sign.signed ? "green" : "red"}
                                      key={sign}
                                  >
                                      {signed}
                                  </Tag>
                              </Flex>

                              <Flex
                                  vertical
                                  style={{ fontSize: "10px" }}
                                  gap="small"
                              >
                                  <i>Criado: {created_at_formatted}</i>
                                  <i>Atualizado: {updated_at_formatted}</i>
                              </Flex>
                          </Flex>
                      );
                  },
              },
              {
                  title: "Ações",
                  dataIndex: "id",
                  key: "id",
                  render: (_, { id, sign }) =>
                      sign.signed ? (
                          <Tooltip title="Documento assinado">
                              <Button
                                  onClick={() => {
                                      props.setLoadingDocSignDownload(
                                          (prev) => ({
                                              ...prev,
                                              [id]: true,
                                          })
                                      );
                                      props.handleDownloadDocumentSigned(
                                          sign.file,
                                          id
                                      );
                                  }}
                                  loading={props.loadingDocSignDownload[id]}
                                  icon={<FaFileDownload />}
                              />
                          </Tooltip>
                      ) : (
                          <Flex gap="small">
                              <Tooltip title="Assinar">
                                  <Button
                                      onClick={() => {
                                          setIdAnamnese(id);
                                          showModalSign();
                                      }}
                                      icon={<FaFileSignature />}
                                  />
                              </Tooltip>
                              <Dropdown
                                  menu={{ items: subitemsAnamnese(id) }}
                                  placement="bottom"
                                  arrow
                              >
                                  <Button icon={<FaEllipsisH />} />
                              </Dropdown>
                          </Flex>
                      ),
              },
          ];

    const subitemsAnamnese = (id) => [
        {
            key: "1",
            label: (
                <Button
                    type="link"
                    style={{
                        display: "flex",
                        alignItems: "center",
                    }}
                    icon={<FaEdit />}
                    onClick={() => {
                        setIdAnamnese(id);
                        showDrawerAnamnese(id);
                    }}
                >
                    Editar
                </Button>
            ),
        },
        {
            key: "2",
            label: (
                <Button
                    type="link"
                    style={{
                        display: "flex",
                        alignItems: "center",
                    }}
                    icon={<FaPrint />}
                    onClick={() => {
                        setIdAnamnese(id);
                        showModalPrintAnamnese();
                    }}
                >
                    Imprimir
                </Button>
            ),
        },
    ];

    return (
        <>
            <Drawer
                title="+ Cadastrar anamnese"
                placement="right"
                closable={!props.isMobile ? false : true}
                width={!props.isMobile ? 378 : "100%"}
                onClose={onCloseAnamnese}
                open={openAnamnese}
                key="createAnamnese"
            >
                <FormAnamnese
                    name="createAnamnese"
                    form={formAnamnese}
                    onFinish={onFinishAnamnese}
                    onFinishFailed={onFinishFailed}
                    openDrawer={openAnamnese}
                    loading={loadingButtonDrawer}
                    buttonText="Cadastrar"
                />
            </Drawer>

            <Drawer
                title="Editar anamnese"
                placement="right"
                closable={!props.isMobile ? false : true}
                width={!props.isMobile ? 378 : "100%"}
                onClose={onCloseAnamneseEdit}
                open={openAnamneseEdit}
                key="editAnamnese"
            >
                <FormAnamnese
                    name="editAnamnese"
                    form={formAnamneseEdit}
                    onFinish={onFinishAnamneseEdit}
                    onFinishFailed={onFinishFailed}
                    openDrawer={openAnamneseEdit}
                    idAnamnese={idAnamnese}
                    loading={loadingButtonDrawer}
                    buttonText="Salvar alterações"
                />
            </Drawer>

            <Modal
                title={
                    <Flex
                        align="center"
                        justify="space-between"
                        className="modal-header-title-button"
                    >
                        <div className="modalTitle">Anamnese</div>

                        <Button
                            type="primary"
                            onClick={() => {
                                setOpenAnamnese(true);
                            }}
                        >
                            + Cadastrar
                        </Button>
                    </Flex>
                }
                open={props.isModalOpen}
                onCancel={() => props.setIsModalOpen(false)}
                centered={true}
                footer={(_, {}) => <></>}
            >
                <Table
                    rowKey="id"
                    columns={columnsAnamnese}
                    dataSource={patientAnamnese}
                    locale={localeTable}
                    loading={loadingAnamnese}
                    pagination={{
                        defaultPageSize: 5,
                    }}
                />
            </Modal>
            <Modal
                title="Imprimir"
                open={isModalPrintAnamneseOpen}
                onCancel={handleCancelPrintAnamnese}
                centered={true}
                width={700}
                footer={(_, {}) => <></>}
            >
                <PDFProvider>
                    <AnamnesePdf
                        id={idAnamnese}
                        patient={props.handleGetPatient(props.patientId)}
                        account={props.account}
                    />
                </PDFProvider>
            </Modal>
            <Modal
                title="Assinatura"
                open={isModalSignOpen}
                onCancel={handleCancelSign}
                centered
                width="100%"
                className="modalDocumentSign"
                footer={(_, {}) => <></>}
            >
                <PDFProvider>
                    <AnamnesePdf
                        id={idAnamnese}
                        patient={props.handleGetPatient(props.patientId)}
                        account={props.account}
                        sign={true}
                    />
                    <ModalSignature
                        close={handleCancelSign}
                        fetch={fetchDataAnamnese}
                        entity={{ name: "anamnese", id: idAnamnese }}
                    />
                </PDFProvider>
            </Modal>
        </>
    );
};

export default PatientAnamnese;
