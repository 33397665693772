import React, { useState, useEffect } from "react";

import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

// Components
import Layout from "../layout/Layout";
import {
    Card,
    List,
    Typography,
    Row,
    Col,
    Avatar,
    Divider,
    Flex,
    Tooltip,
} from "antd";
import {
    UserOutlined,
    ExclamationCircleOutlined,
    InfoCircleOutlined,
} from "@ant-design/icons";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

// Api
import axios from "../api/axios";
import pattern from "../api/pattern";

// Variables
const { endpoints } = pattern;
const { localeTable } = pattern;

dayjs.extend(customParseFormat);

const DailyPatients = () => {
    const [currentTime, setCurrentTime] = useState(dayjs());
    const [appointments, setAppointments] = useState([]);
    const [selectedAppointment, setSelectedAppointment] = useState(null);

    // Novos estados para armazenar histórico de agendamentos, evoluções e respostas de anamnese
    const [patientHistory, setPatientHistory] = useState([]);
    const [evolutionHistory, setEvolutionHistory] = useState([]);
    const [recentAnamneseAnswers, setRecentAnamneseAnswers] = useState([]);

    // Função para buscar dados do dia
    const fetchData = async () => {
        try {
            const response = await axios.get(endpoints.schedules.scheduleDay);
            setAppointments(response.data || []);
        } catch (error) {
            console.error("Erro ao obter dados:", error);
        }
    };

    // Exemplo de funções para buscar histórico de agendamentos e evoluções
    const fetchPatientHistory = async (patientId) => {
        try {
            const response = await axios.get(
                endpoints.schedules.history.replace("${}", patientId)
            );
            setPatientHistory(response.data || []);
        } catch (error) {
            console.error("Erro ao obter histórico de agendamentos:", error);
        }
    };

    const fetchEvolutionHistory = async (patientId) => {
        try {
            const response = await axios.get(
                endpoints.evolutions.index.replace("${}", patientId)
            );
            setEvolutionHistory(response.data || []);
        } catch (error) {
            console.error("Erro ao obter histórico de evoluções:", error);
        }
    };

    // Função para buscar a anamnese mais recente com respostas "Sim" ou texto
    const fetchRecentAnamneseAnswers = async (patientId) => {
        try {
            // Ajuste a rota conforme seu endpoint (ex.: /patients/:id/anamneses/recent-answers)
            const response = await axios.get(
                endpoints.anamneses.indexRecentAnswersAttention.replace(
                    "${}",
                    patientId
                )
            );
            setRecentAnamneseAnswers(response.data || []);
        } catch (error) {
            console.error("Erro ao obter respostas de anamnese:", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    // Atualiza o relógio a cada minuto
    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentTime(dayjs());
        }, 1000 * 60);
        return () => clearInterval(timer);
    }, []);

    // Verifica se um agendamento está ativo (entre dh_start e dh_end)
    const isAppointmentActive = (appointment) => {
        const now = dayjs();
        const start = dayjs(appointment.dh_start);
        const end = dayjs(appointment.dh_end);
        return now.isAfter(start) && now.isBefore(end);
    };

    // Seleciona automaticamente o agendamento ativo
    useEffect(() => {
        const activeAppointment = appointments.find((appt) =>
            isAppointmentActive(appt)
        );
        if (activeAppointment) {
            setSelectedAppointment(activeAppointment);
        }
    }, [appointments, currentTime]);

    // Quando um novo agendamento é selecionado, busque os históricos do paciente + anamnese recente
    useEffect(() => {
        if (selectedAppointment && selectedAppointment.patient_id) {
            fetchPatientHistory(selectedAppointment.patient_id);
            fetchEvolutionHistory(selectedAppointment.patient_id);
            fetchRecentAnamneseAnswers(selectedAppointment.patient_id);
        } else {
            // Se não há paciente selecionado, limpa arrays
            setPatientHistory([]);
            setEvolutionHistory([]);
            setRecentAnamneseAnswers([]);
        }
    }, [selectedAppointment]);

    // Ordena os agendamentos por dh_start
    const sortedAppointments = [...appointments].sort((a, b) =>
        dayjs(a.dh_start).diff(dayjs(b.dh_start))
    );

    const content = (
        <Row gutter={16} style={{ padding: "24px" }}>
            {/* LISTA DE AGENDAMENTOS */}
            <Col span={14}>
                <Typography.Title level={2}>
                    {currentTime.format("HH:mm")}
                </Typography.Title>

                <List
                    dataSource={sortedAppointments}
                    locale={localeTable}
                    renderItem={(appointment) => {
                        const start = dayjs(appointment.dh_start);
                        const end = dayjs(appointment.dh_end);

                        // URL da foto (exemplo)
                        const photoURL = appointment.patientPhotoKey
                            ? `https://d23q1c3j818rpf.cloudfront.net/${appointment.patientPhotoKey}`
                            : null;

                        return (
                            <List.Item
                                style={{ paddingLeft: 0, paddingRight: 0 }}
                            >
                                <Card
                                    hoverable
                                    onClick={() =>
                                        setSelectedAppointment(appointment)
                                    }
                                    style={{
                                        width: "100%",
                                        backgroundColor: isAppointmentActive(
                                            appointment
                                        )
                                            ? "#e6f7ff"
                                            : "#fff",
                                        borderLeft: `8px solid ${
                                            appointment.status_color || "#fff"
                                        }`,
                                        boxShadow:
                                            selectedAppointment?.id ===
                                            appointment.id
                                                ? "0 0 10px 1px rgb(201, 201, 201)"
                                                : "none",
                                    }}
                                >
                                    {/* CONTAINER FLEX para texto à esquerda e avatar à direita */}
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        {/* BLOCO ESQUERDO */}
                                        <div style={{ marginRight: 8 }}>
                                            <Typography.Text strong>
                                                {start.format("HH:mm")} -{" "}
                                                {end.format("HH:mm")}
                                            </Typography.Text>
                                            <Typography.Paragraph
                                                style={{ margin: 0 }}
                                            >
                                                {appointment.patient_name}
                                            </Typography.Paragraph>
                                            <Typography.Text type="secondary">
                                                Status:{" "}
                                                {appointment.status_name}{" "}
                                                {appointment.categories !==
                                                "-" ? (
                                                    <>
                                                        {" "}
                                                        |{" "}
                                                        {appointment.categories
                                                            ?.map(
                                                                (cat) =>
                                                                    cat.name
                                                            )
                                                            .join(", ")}
                                                    </>
                                                ) : (
                                                    ""
                                                )}
                                            </Typography.Text>
                                        </div>

                                        {/* BLOCO DIREITO: Avatar/foto do paciente */}
                                        <Avatar
                                            size={67}
                                            shape="circle"
                                            src={photoURL}
                                            icon={!photoURL && <UserOutlined />}
                                        />
                                    </div>
                                </Card>
                            </List.Item>
                        );
                    }}
                />
            </Col>

            {/* DETALHES DO AGENDAMENTO SELECIONADO */}
            <Col span={10}>
                {selectedAppointment && (
                    <Card
                        title="Ficha do Paciente"
                        className="patientRecord"
                        bordered={false}
                        style={{
                            position: "sticky",
                            top: "24px",
                        }}
                    >
                        <PerfectScrollbar options={{ suppressScrollX: true }}>
                            <Row gutter={16}>
                                <Col span={12}>
                                    {/* Seção de detalhes básicos */}
                                    <Typography.Paragraph>
                                        <strong>Paciente:</strong>{" "}
                                        {selectedAppointment.patient_name}
                                    </Typography.Paragraph>
                                    <Typography.Paragraph>
                                        <strong>Data:</strong>{" "}
                                        {selectedAppointment.date}
                                    </Typography.Paragraph>
                                    <Typography.Paragraph>
                                        <strong>Início - Fim:</strong>{" "}
                                        {dayjs(
                                            selectedAppointment.dh_start
                                        ).format("HH:mm")}{" "}
                                        -{" "}
                                        {dayjs(
                                            selectedAppointment.dh_end
                                        ).format("HH:mm")}
                                    </Typography.Paragraph>
                                    <Typography.Paragraph>
                                        <strong>Categoria(s):</strong>{" "}
                                        {selectedAppointment.categories !== "-"
                                            ? selectedAppointment.categories
                                                  ?.map((cat) => cat.name)
                                                  .join(", ")
                                            : selectedAppointment.categories}
                                    </Typography.Paragraph>
                                </Col>
                                <Col span={12}>
                                    <Typography.Paragraph>
                                        <strong>Status:</strong>{" "}
                                        {selectedAppointment.status_name}
                                    </Typography.Paragraph>

                                    <Typography.Paragraph>
                                        <strong>Convênio(s):</strong>{" "}
                                        {selectedAppointment.patient_convenios !==
                                        "-"
                                            ? selectedAppointment.patient_convenios
                                                  ?.map((conv) => conv.name)
                                                  .join(", ")
                                            : selectedAppointment.patient_convenios}
                                    </Typography.Paragraph>
                                    <Typography.Paragraph>
                                        <strong>Antecipável:</strong>{" "}
                                        {selectedAppointment.is_anticipated
                                            ? "Sim"
                                            : "Não"}
                                    </Typography.Paragraph>
                                </Col>
                            </Row>
                            <Divider />

                            {/* Seção de Anamnese Recente */}
                            <Typography.Title level={5}>
                                Anamnese{" "}
                                <Tooltip title="Anamnese recente com todos os problemas de saúde do paciente">
                                    <InfoCircleOutlined />
                                </Tooltip>
                            </Typography.Title>
                            <List
                                size="small"
                                dataSource={recentAnamneseAnswers}
                                className="todayCalendarDetailsList"
                                pagination={{ pageSize: 3 }}
                                locale={localeTable}
                                renderItem={(answerItem) => (
                                    <List.Item>
                                        <Flex gap="small">
                                            <ExclamationCircleOutlined
                                                style={{ color: "orange" }}
                                            />
                                            <Typography.Text>
                                                <strong>
                                                    {answerItem.question}
                                                </strong>{" "}
                                                {answerItem.answer}{" "}
                                            </Typography.Text>
                                        </Flex>
                                    </List.Item>
                                )}
                            />

                            <Divider />
                            {/* Seção de histórico de agendamentos */}
                            <Typography.Title
                                level={5}
                                style={{ marginBottom: 0 }}
                            >
                                Histórico de Agendamentos
                            </Typography.Title>
                            <List
                                size="small"
                                dataSource={patientHistory}
                                className="todayCalendarDetailsList"
                                locale={localeTable}
                                pagination={{ pageSize: 3 }}
                                renderItem={(item) => (
                                    <List.Item>
                                        <Typography.Text>
                                            <strong>
                                                {item.date} - {item.time}
                                            </strong>{" "}
                                            | {item.status_name}{" "}
                                            {item.categories !== "-" ? (
                                                <>
                                                    |{" "}
                                                    {item.categories
                                                        ?.map((cat) => cat.name)
                                                        .join(", ")}
                                                </>
                                            ) : (
                                                ""
                                            )}
                                        </Typography.Text>
                                    </List.Item>
                                )}
                            />

                            <Divider />

                            {/* Seção de histórico de evoluções */}
                            <Typography.Title level={5}>
                                Histórico de Evoluções
                            </Typography.Title>
                            <List
                                size="small"
                                className="todayCalendarDetailsList"
                                dataSource={evolutionHistory}
                                pagination={{ pageSize: 3 }}
                                locale={localeTable}
                                renderItem={(evolution) => (
                                    <List.Item>
                                        <Typography.Paragraph
                                            style={{ margin: 0 }}
                                        >
                                            <strong>
                                                {evolution.dateFormatted}:
                                            </strong>{" "}
                                            {evolution.description}
                                        </Typography.Paragraph>
                                    </List.Item>
                                )}
                            />
                        </PerfectScrollbar>
                    </Card>
                )}
            </Col>
        </Row>
    );

    return <Layout tabkey="100" content={content} />;
};

export default DailyPatients;
