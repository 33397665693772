//React
import React, { useState, useEffect } from "react";
import { useAuth } from "../context/AuthProvider";

//Components
import SelectSearch from "./SelectSearch";
import TextEditor from "./TextEditor";

//Icons
import { FaClock } from "react-icons/fa";
import { AiOutlineSchedule } from "react-icons/ai";

//Library components
import {
    Button,
    Form,
    Input,
    Select,
    DatePicker,
    TimePicker,
    List,
    Modal,
    Tag,
    Radio,
} from "antd";

//Api
import axios from "../api/axios";
import pattern from "../api/pattern";

//Settings
import dayjs from "dayjs";
import locale from "antd/es/date-picker/locale/pt_BR";

//Variables
const { endpoints } = pattern;

const FormSchedule = (props) => {
    const { account, userId, role } = useAuth();

    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedDentist, setSelectedDentist] = useState(null);

    const [disabledTimePicker, setDisabledTimePicker] = useState(true);
    const [availableTimes, setAvailableTimes] = useState([]);
    const [selectSearchValue, setSelectSearchValue] = useState();

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleFetchAvailableTimes = async (date, dentist_id) => {
        const response = await axios.post(
            endpoints.schedules.availableTimes,
            { date, dentist_id },
            {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            }
        );
        setAvailableTimes(response.data);
    };

    const handleDisabledDateFuture = (current) => {
        return current && current > dayjs().endOf("day");
    };
    const handleDisabledDatePast = (current) => {
        return current && current < dayjs().startOf("day");
    };

    const handleDisabledTimePicker = () => {
        const dateValue = props.form.getFieldValue("date");

        console.log(dateValue);
        if (dateValue === undefined || dateValue === null) {
            return setDisabledTimePicker(true);
        }

        setDisabledTimePicker(false);
    };

    const handleDatePickerChange = (date) => {
        if (date !== null) {
        } else {
            setAvailableTimes([]);
        }
        console.log(date);
        props.form.resetFields(["timerange"]);
        setSelectedDate(date);
        handleDisabledTimePicker();
    };

    useEffect(() => {
        const dateFormatted = dayjs(selectedDate).format("YYYY-MM-DD");

        if (
            typeof selectedDentist === "number" &&
            dayjs(selectedDate).isValid()
        ) {
            handleFetchAvailableTimes(dateFormatted, selectedDentist);
        }

        if (dayjs(selectedDate).isValid() && role === "DENTIST") {
            handleFetchAvailableTimes(dateFormatted, userId);
        }
    }, [selectedDentist, selectedDate]);

    const handleDisabledTime = (_) => {
        // Extrai horas e minutos de opening_start e opening_end
        const [openingHour, openingMinutes] = account.opening_start
            .split(":")
            .map(Number);
        const [closingHour, closingMinutes] = account.opening_end
            .split(":")
            .map(Number);

        if (selectedDate) {
            const selectedDateStart = dayjs(selectedDate).startOf("day");
            const isSameSelectedDate = selectedDateStart.isSame(_, "day");

            if (isSameSelectedDate) {
                const currentHour = dayjs().hour();
                const currentMinute = dayjs().minute();

                return {
                    disabledHours: () =>
                        Array.from({ length: 24 }, (_, i) => i).filter(
                            (hour) =>
                                hour < openingHour ||
                                hour > closingHour ||
                                (hour === openingHour &&
                                    currentHour === openingHour &&
                                    currentMinute < openingMinutes) ||
                                (hour === closingHour &&
                                    currentHour === closingHour &&
                                    currentMinute >= closingMinutes)
                        ),
                    disabledMinutes: (selectedHour) => {
                        if (selectedHour === currentHour) {
                            return Array.from(
                                { length: currentMinute },
                                (_, i) => i
                            );
                        } else if (selectedHour === openingHour) {
                            return Array.from(
                                { length: 60 },
                                (_, i) => i
                            ).filter((minute) => minute < openingMinutes);
                        } else if (selectedHour === closingHour) {
                            return Array.from(
                                { length: 60 },
                                (_, i) => i
                            ).filter((minute) => minute >= closingMinutes);
                        }
                        return [];
                    },
                };
            }
        }
        return {
            disabledHours: () =>
                Array.from({ length: 24 }, (_, i) => i).filter(
                    (hour) => hour < openingHour || hour > closingHour
                ),
            disabledMinutes: (selectedHour) => {
                if (selectedHour === openingHour) {
                    return Array.from({ length: 60 }, (_, i) => i).filter(
                        (minute) => minute < openingMinutes
                    );
                } else if (selectedHour === closingHour) {
                    return Array.from({ length: 60 }, (_, i) => i).filter(
                        (minute) => minute > closingMinutes
                    );
                }
                return [];
            },
        };
    };

    const SimNaoInput = ({ value = {}, onChange }) => {
        const [data, setData] = useState({
            resposta: value.resposta,
            texto: value.texto,
        });

        const triggerChange = (changedValue) => {
            onChange?.({ ...data, ...changedValue });
        };

        const onRespostaChange = (e) => {
            setData({ ...data, resposta: e.target.value });
            triggerChange({ resposta: e.target.value });
        };

        return (
            <Radio.Group value={data.resposta} onChange={onRespostaChange}>
                <Radio value={true}>Sim</Radio>
                <Radio value={false}>Não</Radio>
            </Radio.Group>
        );
    };

    const handleRenderFormItens = (form) => {
        switch (form.formType) {
            case "input":
                return (
                    <Input
                        placeholder={form.formPlaceholder}
                        type={form.formInputType}
                    />
                );
            case "searchInput":
                return (
                    <SelectSearch
                        placeholder={form.formPlaceholder}
                        mode={form.formSelectMode}
                        value={selectSearchValue}
                        setValue={setSelectSearchValue}
                        initialData={
                            props.selectSearchProps.data[form.formSelectEntity]
                        }
                        endpoint={props.selectSearchProps.endpoint[
                            form.formSelectEntity
                        ].replace("${}", [
                            props.form.getFieldValue(form.formSelectMain),
                        ])}
                        onChange={(value) => {
                            props.form.setFieldsValue({
                                [form.formItemName]: value,
                            });
                            if (form.formSelectEntity === "dentists") {
                                setSelectedDentist(value);
                            }
                        }}
                    />
                );
            case "date":
                return (
                    <DatePicker
                        style={{ width: "100%" }}
                        placeholder={form.formPlaceholder}
                        locale={locale}
                        format={"DD/MM/YYYY"}
                        onChange={(value) => {
                            handleDatePickerChange(value);
                        }}
                        disabledDate={
                            form.formDisabledDate
                                ? form.formDisabledDate === "future"
                                    ? handleDisabledDateFuture
                                    : handleDisabledDatePast
                                : null
                        }
                    />
                );
            case "timePicker":
                return (
                    <TimePicker.RangePicker
                        style={{ width: "100%" }}
                        placeholder={["Horário início", "Horário fim"]}
                        format="HH:mm"
                        minuteStep={10}
                        disabled={disabledTimePicker}
                        disabledTime={handleDisabledTime}
                    />
                );
            case "select":
                return (
                    <Select
                        style={{ width: "100%" }}
                        placeholder={form.formPlaceholder}
                        mode={form.formSelectMode}
                        options={form.formSelectOptions}
                    />
                );
            case "textArea":
                return (
                    <TextEditor
                        form={props.form}
                        formItemName={form.formItemName}
                        readOnly={false}
                    />
                );
            case "radio":
                return (
                    <Radio.Group>
                        <Radio value={true}>Sim</Radio>
                        <Radio value={false}>Não</Radio>
                    </Radio.Group>
                );
        }
    };

    useEffect(() => {
        handleDisabledTimePicker();
        setAvailableTimes([]);
        setSelectedDate(null);
        setSelectedDentist(null);
    }, [props.openDrawer]);

    return (
        <Form
            id="formSchedule"
            name={props.name}
            onFinish={props.onFinish}
            onFinishFailed={props.onFinishFailed}
            autoComplete="off"
            form={props.form}
            layout="vertical"
        >
            {props.formItens.map((form) => (
                <React.Fragment key={form.formItemName}>
                    <Form.Item
                        name={form.formItemName}
                        label={form.formItemLabel}
                        rules={form.formItemRules}
                        initialValue={form.formItemInitialValue}
                    >
                        {handleRenderFormItens(form)}
                    </Form.Item>
                    {form.formType === "date" ? (
                        <Button
                            style={{
                                padding: "0px",
                                display:
                                    role !== "DENTIST"
                                        ? selectedDate === null ||
                                          selectedDentist === null
                                            ? "none"
                                            : "flex"
                                        : selectedDate === null
                                        ? "none"
                                        : "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                position: "absolute",
                                top: 165,
                                right: 24,
                                zIndex: 5000,
                            }}
                            onClick={() => {
                                setIsModalOpen(true);
                            }}
                            type="link"
                            icon={<AiOutlineSchedule />}
                        >
                            Horários disponíveis
                        </Button>
                    ) : (
                        ""
                    )}
                </React.Fragment>
            ))}

            <Modal
                open={isModalOpen}
                width={330}
                onCancel={handleCancel}
                footer={null}
                style={{
                    position: "absolute",
                    right: 24,
                    top: 168,
                }}
            >
                <List
                    size="small"
                    locale={{
                        emptyText:
                            selectedDate === null
                                ? "Selecione uma data!"
                                : "Nenhum horário disponível",
                    }}
                    dataSource={availableTimes}
                    renderItem={(item, i) => (
                        <List.Item style={{ padding: "8px 0px" }}>
                            <Tag
                                icon={<FaClock />}
                                key={i}
                                color="green"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: "5px",
                                    width: "100%",
                                    fontSize: "13px",
                                    margin: "0px",
                                }}
                            >
                                {item.start} - {item.end}
                            </Tag>
                        </List.Item>
                    )}
                />
            </Modal>
            <Form.Item>
                <Button
                    style={{ width: "100%" }}
                    type="primary"
                    htmlType="submit"
                >
                    {props.buttonText}
                </Button>
            </Form.Item>
        </Form>
    );
};
export default FormSchedule;
