//React
import React, { useState, useEffect, useRef } from "react";

//Components
import FormPatientTreatment from "../../components/FormPatientTreatment";

//Icons
import { FaEllipsisH, FaEdit, FaCheck } from "react-icons/fa";
import { FaArrowRotateLeft } from "react-icons/fa6";

//Library components
import {
    Flex,
    Table,
    Modal,
    Tag,
    Form,
    message,
    Tooltip,
    Button,
    Dropdown,
    Drawer,
    Popconfirm,
    Descriptions,
} from "antd";

//Api
import axios from "../../api/axios";
import pattern from "../../api/pattern";

//Variables
const { endpoints, forms, localeTable } = pattern;

const PatientTreatment = (props) => {
    const [loadingTreatments, setLoadingTreatments] = useState(true);

    const [idPatientTreatment, setIdPatientTreatment] = useState(null);
    const [dataPatientTreatments, setDataPatientTreatments] = useState([]);
    const [dataActiveDentists, setDataActiveDentists] = useState([]);
    const [dataPatientTreatment, setDataPatientTreatment] = useState({});
    const [dataUpdateTreatments, setDataUpdateTreatments] = useState([]);
    const [openPatientTreatment, setOpenPatientTreatment] = useState(false);
    const [openPatientTreatmentEdit, setOpenPatientTreatmentEdit] =
        useState(false);
    const [formTreatment] = Form.useForm();
    const [formTreatmentEdit] = Form.useForm();
    const countRender = useRef(0);

    const fetchDataPatientTreatments = async () => {
        try {
            const response = await axios.get(
                endpoints.treatments.indexPatientTreatments.replace(
                    "${}",
                    props.patientId
                )
            );
            setDataPatientTreatments(response.data);
            setLoadingTreatments(false);
        } catch (error) {
            console.error("Erro ao obter dados:", error);
        }
    };

    const fetchDataUpdateTreatments = async (id) => {
        try {
            const response = await axios.get(
                endpoints.treatments.indexUpdateTreatments.replace("${}", id)
            );

            setDataUpdateTreatments(response.data);
        } catch (error) {
            console.error("Erro ao obter dados:", error);
        }
    };

    const fetchDataActiveDentists = async () => {
        try {
            const response = await axios.get(
                endpoints.users.indexActiveDentists
            );
            setDataActiveDentists(response.data);
        } catch (error) {
            console.error("Erro ao obter dados:", error);
        }
    };

    useEffect(() => {
        if (countRender.current < 1) {
            countRender.current += 1;
            return;
        }
        if (props.isModalOpen === false) {
            setDataPatientTreatments([]);
            return;
        }

        fetchDataActiveDentists();
        fetchDataPatientTreatments();
    }, [props.isModalOpen]);

    useEffect(() => {
        props.fetchDataActiveTreatments();
    }, [openPatientTreatment]);

    const onClosePatientTratments = () => {
        setOpenPatientTreatment(false);
        formTreatment.resetFields();
    };

    const onClosePatientTratmentsEdit = () => {
        setOpenPatientTreatmentEdit(false);
        formTreatmentEdit.resetFields();
    };

    const handleFinishPatientTreatment = async (id, status) => {
        try {
            await axios.post(
                endpoints.treatments.finishPatientTreatment.replace("${}", id),
                { finished: status },
                {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                }
            );

            message.success(
                status
                    ? "Tratamento finalizado com sucesso!"
                    : "Tratamento atualizado com sucesso!"
            );

            fetchDataPatientTreatments();
        } catch (error) {
            console.log(error);
            message.error(error.response.data.message);
        }
    };

    const showDrawerPatientTreatmentEdit = (id) => {
        const patientTreatment = {
            ...dataPatientTreatments.find(
                (patientTreatment) => patientTreatment.id === id
            ),
        };
        setDataPatientTreatment(patientTreatment);
        formTreatmentEdit.setFieldsValue(patientTreatment);
        fetchDataUpdateTreatments(id);
        setOpenPatientTreatmentEdit(true);
    };

    const onFinishPatientTreatment = async (values) => {
        const formattedValues = {
            ...values,
            patient_id: props.patientId,
            dentist_id:
                props.role === "DENTIST" ? props.userId : values.dentist_id,
            treatment_value: props.dataActiveTreatments.find(
                (treatment) => treatment.id === values.treatment_id
            ).value,
        };

        try {
            await axios.post(
                endpoints.treatments.createPatientTreatments,
                formattedValues,
                {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                }
            );

            onClosePatientTratments();

            message.success("Tratamento adicionado com sucesso!");

            fetchDataPatientTreatments();
        } catch (error) {
            console.log(error);
            message.error("Campo(s) inválido(s)!");
        }
    };

    const onFinishPatientTreatmentEdit = async (values) => {
        const selectedTreatment = dataPatientTreatments.find(
            (patientTreatment) => patientTreatment.id === idPatientTreatment
        );
        const formattedValues = {
            ...values,
            dentist_id:
                props.role === "DENTIST"
                    ? selectedTreatment.dentist_id
                        ? selectedTreatment.dentist_id
                        : props.userId
                    : values.dentist_id,
            treatment_value: dataUpdateTreatments.find(
                (treatment) => treatment.id === values.treatment_id
            ).value,
        };

        try {
            await axios.post(
                endpoints.treatments.updatePatientTreatment.replace(
                    "${}",
                    idPatientTreatment
                ),
                formattedValues,
                {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                }
            );

            onClosePatientTratmentsEdit();

            message.success("Tratamento atualizado com sucesso!");

            fetchDataPatientTreatments();
        } catch (error) {
            console.log(error);
            message.error("Campo(s) inválido(s)!");
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    const subitemsPatientTreatment = (id, finished) => [
        {
            key: "1",
            label: (
                <Button
                    type="link"
                    style={{
                        display: "flex",
                        alignItems: "center",
                    }}
                    icon={<FaCheck />}
                    onClick={() => {
                        handleFinishPatientTreatment(id, true);
                    }}
                >
                    Finalizar
                </Button>
            ),
        },
        {
            key: "2",
            label: (
                <Button
                    type="link"
                    style={{
                        display: "flex",
                        alignItems: "center",
                    }}
                    icon={<FaEdit />}
                    onClick={() => {
                        setIdPatientTreatment(id);
                        showDrawerPatientTreatmentEdit(id);
                    }}
                >
                    Editar
                </Button>
            ),
        },
    ];

    const columnsTreatments = !props.isMobile
        ? [
              {
                  title: "Dentista",
                  dataIndex: "dentistName",
                  key: "dentistName",
                  width: "150px",
              },
              {
                  title: "Nome",
                  dataIndex: "treatment_name",
                  key: "treatment_name",
                  width: "150px",
              },
              {
                  title: "Valor",
                  dataIndex: "final_value_formatted",
                  key: "final_value_formatted",
                  width: "100px",
              },
              {
                  title: "Convênio",
                  dataIndex: "convenio",
                  key: "convenio",
                  width: "80px",
                  render: (_, { convenio }) => (
                      <Tag key={convenio.id} color={convenio.color}>
                          {convenio.name}
                      </Tag>
                  ),
              },
              {
                  title: "Finalizado",
                  dataIndex: "finished",
                  key: "finished",
                  width: "80px",
                  render: (_, { finished, finishedText }) => (
                      <Tag color={finished ? "green" : "red"}>
                          {finishedText}
                      </Tag>
                  ),
              },
              {
                  title: "Ações",
                  dataIndex: "id",
                  key: "id",
                  width: "70px",
                  render: (_, { id, finished }) =>
                      finished ? (
                          <Flex>
                              <Tooltip title="Desfazer">
                                  <Popconfirm
                                      title="Desfazer finalizar"
                                      description="Você tem certeza?"
                                      onConfirm={() =>
                                          handleFinishPatientTreatment(
                                              id,
                                              false
                                          )
                                      }
                                      okText="Sim"
                                      cancelText="Não"
                                  >
                                      <Button
                                          danger
                                          style={{
                                              display: "flex",
                                              alignItems: "center",
                                              marginRight: "10px",
                                          }}
                                      >
                                          <FaArrowRotateLeft />
                                      </Button>
                                  </Popconfirm>
                              </Tooltip>
                          </Flex>
                      ) : (
                          <Flex>
                              <Tooltip title="Finalizar">
                                  <Button
                                      style={{
                                          display: "flex",
                                          alignItems: "center",
                                          marginRight: "10px",
                                      }}
                                      onClick={() => {
                                          handleFinishPatientTreatment(
                                              id,
                                              true
                                          );
                                      }}
                                  >
                                      <FaCheck />
                                  </Button>
                              </Tooltip>
                              <Tooltip title="Editar">
                                  <Button
                                      style={{
                                          display: "flex",
                                          alignItems: "center",
                                          marginRight: "10px",
                                      }}
                                      onClick={() => {
                                          setIdPatientTreatment(id);
                                          showDrawerPatientTreatmentEdit(id);
                                      }}
                                  >
                                      <FaEdit />
                                  </Button>
                              </Tooltip>
                          </Flex>
                      ),
              },
          ]
        : [
              {
                  title: "Nome",
                  dataIndex: "treatment_name",
                  key: "treatment_name",
              },
              {
                  title: "Ações",
                  dataIndex: "id",
                  key: "id",
                  width: "70px",
                  render: (_, { id, finished }) =>
                      finished ? (
                          <Popconfirm
                              title="Desfazer finalizar"
                              description="Você tem certeza?"
                              onConfirm={() =>
                                  handleFinishPatientTreatment(id, false)
                              }
                              okText="Sim"
                              cancelText="Não"
                          >
                              <Button
                                  danger
                                  style={{
                                      display: "flex",
                                      alignItems: "center",
                                      marginRight: "10px",
                                  }}
                              >
                                  <FaArrowRotateLeft />
                              </Button>
                          </Popconfirm>
                      ) : (
                          <Dropdown
                              menu={{
                                  items: subitemsPatientTreatment(id, finished),
                              }}
                              placement="bottom"
                              arrow
                          >
                              <Button icon={<FaEllipsisH />} />
                          </Dropdown>
                      ),
              },
          ];

    const expandedRowRenderPatientTreatment = (record) => {
        const items = !props.isMobile
            ? []
            : [
                  {
                      label: "Valor",
                      children: record.final_value_formatted,
                  },
                  {
                      label: "Convênio",
                      children: (
                          <Tag
                              key={record.convenio.id}
                              color={record.convenio.color}
                          >
                              {record.convenio.name}
                          </Tag>
                      ),
                  },
                  {
                      label: "Finalizado",
                      children: (
                          <Tag color={record.finished ? "green" : "red"}>
                              {record.finishedText}
                          </Tag>
                      ),
                  },
              ];

        return <Descriptions items={items} />;
    };

    return (
        <>
            <Drawer
                title="+ Adicionar tratamento"
                placement="right"
                closable={!props.isMobile ? false : true}
                width={!props.isMobile ? 500 : "100%"}
                onClose={onClosePatientTratments}
                open={openPatientTreatment}
                key="addTreatment"
            >
                <FormPatientTreatment
                    name="addTreatment"
                    form={formTreatment}
                    onFinish={onFinishPatientTreatment}
                    onFinishFailed={onFinishFailed}
                    role={props.role}
                    initialValue={0}
                    dataActiveTreatments={props.dataActiveTreatments}
                    dataActiveDentists={dataActiveDentists}
                    openDrawer={openPatientTreatment}
                    buttonText="Adicionar tratamento"
                />
            </Drawer>

            <Drawer
                title="Editar tratamento"
                placement="right"
                closable={!props.isMobile ? false : true}
                width={!props.isMobile ? 500 : "100%"}
                onClose={onClosePatientTratmentsEdit}
                open={openPatientTreatmentEdit}
                key="editTreatment"
            >
                <FormPatientTreatment
                    name="editTreatment"
                    form={formTreatmentEdit}
                    onFinish={onFinishPatientTreatmentEdit}
                    onFinishFailed={onFinishFailed}
                    role={props.role}
                    dataActiveTreatments={dataUpdateTreatments}
                    dataActiveDentists={dataActiveDentists}
                    initialValue={dataPatientTreatment.final_value}
                    openDrawer={openPatientTreatmentEdit}
                    buttonText="Salvar alterações"
                />
            </Drawer>

            <Modal
                title={
                    <Flex
                        align="center"
                        justify="space-between"
                        className="modal-header-title-button"
                    >
                        <div className="modalTitle">Tratamentos</div>
                        <Button
                            type="primary"
                            onClick={() => {
                                setOpenPatientTreatment(true);
                            }}
                        >
                            + Incluir
                        </Button>
                    </Flex>
                }
                open={props.isModalOpen}
                onCancel={() => props.setIsModalOpen(false)}
                centered={true}
                width={1000}
                footer={(_, {}) => <></>}
            >
                <Table
                    rowKey="id"
                    columns={columnsTreatments}
                    dataSource={dataPatientTreatments}
                    locale={localeTable}
                    loading={loadingTreatments}
                    expandable={
                        !props.isMobile
                            ? false
                            : {
                                  expandedRowRender:
                                      expandedRowRenderPatientTreatment,
                              }
                    }
                    pagination={{
                        defaultPageSize: !props.isMobile ? 5 : 3,
                    }}
                />
            </Modal>
        </>
    );
};

export default PatientTreatment;
