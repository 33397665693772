//React
import React, { useState, useEffect, useRef } from "react";

//Icons
import { FaCalendarAlt, FaClock } from "react-icons/fa";

//Library components
import { Flex, Table, Modal, Tag } from "antd";

//Api
import axios from "../../api/axios";
import pattern from "../../api/pattern";

//Variables
const { endpoints, localeTable } = pattern;

const PatientHistoric = (props) => {
    const [loadingHistoric, setLoadingHistoric] = useState(true);
    const [patientHistory, setPatientHistory] = useState([]);
    const countRender = useRef(0);

    const fetchDataPatientHistory = async () => {
        try {
            const response = await axios.get(
                endpoints.schedules.history.replace("${}", props.patientId)
            );
            setPatientHistory(response.data);
            setLoadingHistoric(false);
        } catch (error) {
            console.error("Erro ao obter dados:", error);
        }
    };

    useEffect(() => {
        if (countRender.current < 1) {
            countRender.current += 1;
            return;
        }
        if (props.isModalOpen === false) {
            setPatientHistory([]);
            return;
        }

        fetchDataPatientHistory();
    }, [props.isModalOpen]);

    const columnsHistory = !props.isMobile
        ? [
              {
                  title: "Data e horário",
                  dataIndex: "date",
                  key: "date",
                  render: (_, { date, time }) => {
                      return (
                          <Flex vertical justify="center">
                              <Flex gap="small" align="center">
                                  <FaCalendarAlt />
                                  {date}
                              </Flex>
                              <Flex gap="small" align="center">
                                  <FaClock /> {time}
                              </Flex>
                          </Flex>
                      );
                  },
              },
              {
                  title: "Dentista",
                  dataIndex: "user_name",
                  key: "user_name",
              },
              {
                  title: "Categoria",
                  dataIndex: "categories",
                  key: "categories",
                  render: (_, { categories }) =>
                      Array.isArray(categories) ? (
                          <>
                              {categories.map(({ id, name, color }) => {
                                  return (
                                      <Tag key={id} color={color}>
                                          {name}
                                      </Tag>
                                  );
                              })}
                          </>
                      ) : (
                          categories
                      ),
              },
              {
                  title: "Status",
                  dataIndex: "status",
                  key: "status",
                  render: (_, { status_name, status_color }) => {
                      return (
                          <Tag color={status_color} key={status_name}>
                              <div style={{ fontWeight: "500" }}>
                                  {status_name.toUpperCase()}
                              </div>
                          </Tag>
                      );
                  },
              },
          ]
        : [
              {
                  title: "Agendamento",
                  key: "date",
                  render: (_, { date, time, status, status_color }) => {
                      return (
                          <Flex vertical justify="center" align="start">
                              <Flex gap="small" align="center">
                                  <FaCalendarAlt />
                                  {date}
                              </Flex>
                              <Flex gap="small" align="center">
                                  <FaClock /> {time}
                              </Flex>
                              <Tag color={status_color} key={status}>
                                  {status.toUpperCase()}
                              </Tag>
                          </Flex>
                      );
                  },
              },
              {
                  title: "Dentista",
                  dataIndex: "user_name",
                  key: "user_name",
              },
              {
                  title: "Categoria",
                  dataIndex: "categories",
                  key: "categories",
                  width: "50%",
                  render: (_, { categories }) =>
                      Array.isArray(categories) ? (
                          <>
                              {categories.map(({ id, name, color }) => {
                                  return (
                                      <Tag key={id} color={color}>
                                          {name}
                                      </Tag>
                                  );
                              })}
                          </>
                      ) : (
                          categories
                      ),
              },
          ];

    return (
        <Modal
            title="Histórico"
            open={props.isModalOpen}
            width={800}
            onCancel={() => props.setIsModalOpen(false)}
            centered={true}
            footer={(_, {}) => <></>}
        >
            <Table
                rowKey="id"
                columns={columnsHistory}
                dataSource={patientHistory}
                locale={localeTable}
                loading={loadingHistoric}
                pagination={{
                    defaultPageSize: !props.isMobile ? 5 : 3,
                }}
            />
        </Modal>
    );
};

export default PatientHistoric;
